import {
  AfterViewInit,
  Component,
  effect,
  forwardRef,
  inject,
  input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule} from "@angular/forms";
import {SubDistrict} from "../../../../generated-model/model";
import {debounceTime, finalize, map, Observable, of, Subject} from "rxjs";
import {CommonModule} from "@angular/common";
import {NgSelectComponent} from "@ng-select/ng-select";
import {SharedCommonModule} from '../../shared-common.module';
import {SubDistrictRest} from '../../../service/SubDistrictRest';

@Component({
  selector: 'app-subdistrict-autocomplete',
  templateUrl: './sub-district-autocomplete.component.html',
  standalone: true,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SubDistrictAutocompleteComponent),
      multi: true
    }
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedCommonModule
  ]
})
export class SubDistrictAutocompleteComponent implements ControlValueAccessor, OnInit, AfterViewInit, OnChanges {

  subDistrictList$: Observable<SubDistrict[]> = of([]);
  term$ = new Subject<string>();
  typeaheadLoading = false;
  value: SubDistrict = undefined;
  id = input<string>('subdistrict');
  name = input<string>('subdistrict');
  districtId = input<string>();
  provinceId = input<string>();
  disabled = input<boolean>(false);
  options = input<{ fetchAll?: boolean }>({fetchAll: false});
  private subDistrictService = inject(SubDistrictRest);
  @ViewChild('ngSelectComponent', {static: true, read: NgSelectComponent}) ngSelectComponent: NgSelectComponent;

  onChange = (value:any) => {
  };
  onTouched = () => {
  };

  compareWithCode = (o1: any, o2: any): boolean => {
    return (o1 == o2) || ((!!o1 && !!o2) && (o1.code == o2.code))
  };

  constructor() {
    effect(() => {
      if (this.districtId()) {
        this.term$
          .pipe(debounceTime(500))
          .subscribe(term => {
            this.typeaheadLoading = true;
            this.subDistrictList$ = this.subDistrictService.search(term,this.districtId()).pipe(
              map(e => {
                return e;
              }),
              finalize(() => this.typeaheadLoading = false)
            )
          });
        this.term$.next('');
      }
    });
  }

  ngAfterViewInit() {
  }

  ngOnInit(): void {
  }

  writeValue(value: SubDistrict) {
    this.value = value;
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  valueChange(value: SubDistrict) {
    this.onChange(value);
    this.onTouched();
  }

  ngOnChanges(changes: SimpleChanges): void {
  }
}
