import {
  AfterViewInit,
  Component,
  forwardRef,
  inject,
  input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR} from '@angular/forms';
import {debounceTime, finalize, map, Observable, of, Subject} from 'rxjs';
import {Province} from '../../../../generated-model/model';
import {NgSelectComponent} from '@ng-select/ng-select';
import {ProvinceRest} from '../../../service/ProvinceRest';
import {AsyncPipe} from '@angular/common';

@Component({
  selector: 'app-province-autocomplete',
  standalone: true,
  imports: [
    FormsModule,
    NgSelectComponent,
    AsyncPipe
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ProvinceAutocompleteComponent),
      multi: true
    }
  ],
  templateUrl: './province-autocomplete.component.html',
  styleUrl: './province-autocomplete.component.scss'
})
export class ProvinceAutocompleteComponent  implements ControlValueAccessor, OnInit, AfterViewInit, OnChanges{
  provinceList$: Observable<Province[]> = of([]);
  term$ = new Subject<string>();
  typeaheadLoading = false;
  value: Province = undefined;
  id = input<string>('province');
  name = input<string>('province');
  disabled = input<boolean>();
  touched = false;
  private provinceResource = inject(ProvinceRest);
  @ViewChild('ngSelectComponent', {static: true, read: NgSelectComponent}) ngSelectComponent: NgSelectComponent;

  onChange = (value:any) => {
  };
  onTouched = () => {
  };

  compareWithCode = (o1: any, o2: any): boolean => {
    return (o1 == o2) || ((!!o1 && !!o2) && (o1.id == o2.id))
  };

  constructor() {
  }

  ngAfterViewInit() {

  }

  ngOnInit(): void {
    this.term$
      .pipe(debounceTime(500))
      .subscribe(term => {
        this.typeaheadLoading = true;
        this.provinceList$ = this.provinceResource.search(term).pipe(
          map(e => {
            return e;
          }),
          finalize(() => this.typeaheadLoading = false)
        );
      });
    this.term$.next('');
  }

  writeValue(value: Province) {
    this.value = value;
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  onValueChange(value: Province) {
    this.onChange(value);
    this.markAsTouched();
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {

  }
}
