import {
  AfterViewInit,
  Component,
  effect,
  forwardRef,
  inject,
  Input,
  input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR} from '@angular/forms';
import {debounceTime, finalize, map, Observable, of, Subject} from 'rxjs';
import {District, SubDistrict} from '../../../../generated-model/model';
import {NgLabelTemplateDirective, NgOptionTemplateDirective, NgSelectComponent} from '@ng-select/ng-select';
import {AsyncPipe, NgIf} from '@angular/common';
import {DistrictRest} from '../../../service/DistrictRest';

@Component({
  selector: 'app-district-autocomplete',
  standalone: true,
  imports: [
    FormsModule,
    NgSelectComponent,
    AsyncPipe,
    NgIf,
    NgLabelTemplateDirective,
    NgOptionTemplateDirective
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DistrictAutocompleteComponent),
      multi: true
    }
  ],
  templateUrl: './district-autocomplete.component.html',
  styleUrl: './district-autocomplete.component.scss'
})
export class DistrictAutocompleteComponent implements ControlValueAccessor, OnInit, AfterViewInit, OnChanges{

  district$: Observable<District[]> = of([]);
  term$ = new Subject<string>();
  typeaheadLoading = false;
  value: District = undefined
  id = input<string>('district');
  name = input<string>('district');
  provinceId = input<string>();
  disabled = input<boolean>();
  options = input<{ fetchAll?: boolean }>({fetchAll: false});
  @ViewChild('ngSelectComponent', {static: true, read: NgSelectComponent}) ngSelectComponent: NgSelectComponent;

  @Input() province = ''

  districtService = inject(DistrictRest)

  onChange = (value: any) => {
  };
  onTouched = () => {
  };

  compareWithCode = (o1: any, o2: any): boolean => {
    return (o1 == o2) || ((!!o1 && !!o2) && (o1.code == o2.code))
  };

  constructor() {
    effect(() => {
      if (this.provinceId()) {
        this.term$
          .pipe(debounceTime(500))
          .subscribe(term => {
            this.typeaheadLoading = true;
            this.district$ = this.districtService.search(term,this.provinceId()).pipe(
              map(e => {
                return e;
              }),
              finalize(() => this.typeaheadLoading = false)
            )
          });
        this.term$.next('');
      }
    });
  }

  ngAfterViewInit() {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  writeValue(value: SubDistrict) {
    this.value = value
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  valueChange(value: SubDistrict) {
    this.onChange(value);
    this.onTouched();
  }

}
