import {inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Province} from '../../generated-model/model';
import {environment} from '../../environments/environment';
import {IgnoreNullHttpParams} from '../shared/Ignore-null-http-params';

@Injectable({providedIn: "root"})
export class SubDistrictRest {
  http = inject(HttpClient);

  search(term: string, district: string) {
    return this.http.get<Province[]>(`${environment.serverUrl}/api/sub-district`,{
      params: IgnoreNullHttpParams.fromObject({term: term, districtId: district}).toHttpParam()
    })
  }
}
