<ng-select [id]="id()"
           #ngSelectComponent
           [name]="name()"
           [items]="provinceList$ | async"
           placeholder="กรุณาเลือก"
           class="novalidate"
           [disabled]="disabled()"
           [clearable]="true"
           [compareWith]="compareWithCode"
           [editableSearchTerm]="true"
           [typeahead]="term$"
           [loading]="typeaheadLoading"
           (ngModelChange)="onValueChange($event)"
           bindLabel="name"
           [(ngModel)]="value">
</ng-select>
